import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useAuthContext } from './AuthContext';
import { apiClient, endpoints, getJWT, getSystemErrorMessage } from '../utils';
import { useMediaQuery, useTheme } from '@mui/material';
import ChatWidget from '../components/ChatWidget';
import { useSearchParams } from 'react-router-dom';

const ChatWidgetContext = createContext({
	isChatWidgetOpen: false,
	setChatWidgetOpen: () => {},
	newMessages: [],
	setNewMessages: () => {},
	messages: [],
	setMessages: () => {},
	selectedTab: '',
	setSelectedTab: () => {},
	selectedQuestion: '',
	setSelectedQuestion: () => {},
	tabsVisible: true,

	chatWidgetWidth: '',
	chatWidgetMinHeight: '',
	chatWidgetMaxHeight: '',
});

export const ChatWidgetProvider = ({ children }) => {
	const { inquiries } = useAuthContext();
	const [searchParams] = useSearchParams();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [isChatWidgetOpen, setChatWidgetOpen] = useState(
		searchParams.has('chat') || (!Boolean(inquiries.length) && !isMobile)
	);
	const [selectedTab, setSelectedTab] = useState('chat');
	const [tabsVisible, setTabsVisible] = useState(true);

	const [chatWidgetWidth, setChatWidgetWidth] = useState(isMobile ? '100vw' : 400);
	const [chatWidgetMinHeight, setChatWidgetMinHeight] = useState(isMobile ? '100%' : 600);
	const [chatWidgetMaxHeight, setChatWidgetMaxHeight] = useState(isMobile ? '100%' : '80vh');

	// chat
	const [messages, setMessages] = useState([]);
	const [newMessages, setNewMessages] = useState([]);

	//help
	const [selectedQuestion, setSelectedQuestion] = useState(null);

	// WebSocket
	const ws = useRef(null);

	useEffect(() => {
		// Fetch initial messages
		fetchAllMessages();

		// Establish WebSocket connection
		getJWT().then((token) => {
			ws.current = new WebSocket(`${endpoints.chatWidgetWebSocket}?token=${token}`);

			ws.current.onopen = () => console.log('WebSocket connection opened');
			ws.current.onmessage = (event) => {
				try {
					const receivedMessage = JSON.parse(event.data);
					if (receivedMessage.type === 'notification' && receivedMessage.content === 'chat') {
						fetchAllMessages();
					}
				} catch (error) {
					console.error('Error parsing WebSocket message:', error);
				}
			};
			ws.current.onerror = (error) => console.error('WebSocket error:', error);
			ws.current.onclose = () => console.log('WebSocket connection closed');

			return () => {
				if (ws.current) {
					ws.current.close();
				}
			};
		});
	}, []);

	useEffect(() => {
		isChatWidgetOpen && fetchAllMessages();
	}, [isChatWidgetOpen]);

	useEffect(() => {
		setTabsVisible(!Boolean(selectedQuestion));

		if (!isMobile) {
			if (Boolean(selectedQuestion)) {
				increaseChatWidget();
			} else {
				decreaseChatWidget();
			}
		}
	}, [selectedQuestion, isMobile]);

	useEffect(() => {
		if (!isMobile) {
			if (selectedTab === 'help' && !selectedQuestion) {
				setChatWidgetMaxHeight(chatWidgetMinHeight);
			} else {
				setChatWidgetMaxHeight('80vh');
			}
		}
	}, [isMobile, selectedTab, selectedQuestion, chatWidgetMinHeight]);

	useEffect(() => {
		if (newMessages && newMessages.length > 0) {
			document.title = `(${newMessages.length}) ShareWell | SaxonWeber`;
		} else {
			document.title = 'ShareWell | SaxonWeber';
		}
	}, [newMessages]);

	const fetchAllMessages = async () => {
		try {
			const response = await apiClient.get(endpoints.getChatMessages);
			const responseData = response ? response.data : null;
			if (responseData && messages.length < responseData.length) {
				setMessages(responseData);
			}
		} catch (error) {
			getSystemErrorMessage(error);
		}
	};

	const increaseChatWidget = () => {
		setChatWidgetWidth(700);
		setChatWidgetMinHeight('82vh');
	};

	const decreaseChatWidget = () => {
		setChatWidgetWidth(400);
		setChatWidgetMinHeight(600);
	};

	return (
		<ChatWidgetContext.Provider
			value={{
				isChatWidgetOpen,
				setChatWidgetOpen,
				newMessages,
				setNewMessages,
				messages,
				setMessages,
				selectedTab,
				setSelectedTab,
				selectedQuestion,
				setSelectedQuestion,
				tabsVisible,
				chatWidgetWidth,
				chatWidgetMinHeight,
				chatWidgetMaxHeight,
			}}
		>
			{children}
			<ChatWidget />
		</ChatWidgetContext.Provider>
	);
};

export const useChatWidgetContext = () => useContext(ChatWidgetContext);
