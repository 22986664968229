import { toast } from 'react-toastify';
import { Button, Stack, Typography } from '@mui/material';

const onPageReload = (newVersion: string) => {
	localStorage.setItem('appVersion', newVersion);
	window.location.reload();
};

const checkVersion = async () => {
	try {
		const response = await fetch('/data/version.json');

		const data = await response.json();
		const currentVersion = localStorage.getItem('appVersion');

		if (currentVersion !== data.version) {
			toast.info(
				<Stack spacing={2} direction={'row'} alignItems={'center'}>
					<Typography>New version of the app is here.</Typography>
					<Button variant={'contained'} size={'small'} onClick={() => onPageReload(data.version)}>
						Refresh page
					</Button>
				</Stack>,
				{
					toastId: 'versionToast',
					position: 'top-center',
					autoClose: false,
					closeOnClick: false,
					theme: 'dark',
					style: {
						width: 480,
					},
				}
			);
		}
	} catch (error) {
		console.error('RunVersionCheck: No version on the server!');
	}
};

export const runVersionCheck = () => {
	// Run immediately
	checkVersion();
	
	// Then set up interval
	setInterval(checkVersion, 900000); // Check every 15 mins
};
