import PageTitle from '../../components/PageTitle';
import { Button, Chip, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useAuthContext, useLoaderContext } from '../../context';
import React, { useEffect, useMemo, useState } from 'react';
import InquiryCard from './components/InquiryCard';
import BidCard from './components/BidCard';
import { EmptyContainer } from '../../styles/common.styles';
import OfferCard from './components/OfferCard';
import TargetPriceCard from './components/TargetPriceCard';
import { runVersionCheck } from '../../utils/version';
const DashboardView = () => {
	const { user, inquiries, reloadUserData, bids, targetPrices, offers } = useAuthContext();
	const { isLoading } = useLoaderContext();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [selectedFilter, setSelectedFilter] = useState('All');

	const filteredBids = useMemo(() => {
		return bids.filter((bid) => bid.fields.Answer === 'Applied');
	}, [bids]);
	const bidsLength = useMemo(() => {
		return filteredBids.length;
	}, [filteredBids]);

	const filteredOffers = useMemo(() => {
		return offers.filter((offer) => offer.answer === 'Applied');
	}, [offers]);
	const offersLength = useMemo(() => {
		return filteredOffers.length;
	}, [filteredOffers]);

	const inquiriesLength = useMemo(() => {
		return inquiries.length;
	}, [inquiries]);

	const targetPricesLength = useMemo(() => {
		return targetPrices.length;
	}, [targetPrices]);

	const summLength = useMemo(() => {
		return bidsLength + offersLength + inquiriesLength + targetPricesLength;
	}, [bidsLength, offersLength, inquiriesLength, targetPricesLength]);

	useEffect(() => {
		if (!isLoading) {
			reloadUserData();
		}

		runVersionCheck();
	}, []);

	const generateFilterChip = (name, number) => {
		return (
			<Chip
				variant="outlined"
				color={selectedFilter === name ? 'primary' : 'default'}
				label={`${name} (${number})`}
				onClick={() => setSelectedFilter(name)}
			/>
		);
	};

	return (
		<>
			<PageTitle
				breadcrumbs={[
					{
						title: 'Home',
					},
				]}
				title={'Dashboard'}
			/>

			{isLoading && (
				<Stack spacing={isMobile ? 2 : 4} width={'100%'}>
					<Skeleton variant="rectangular" height={isMobile ? 240 : 310} />
					<Skeleton variant="rectangular" height={isMobile ? 240 : 310} />
					<Skeleton variant="rectangular" height={isMobile ? 240 : 310} />
				</Stack>
			)}

			{!isLoading && summLength === 0 && (
				<EmptyContainer>
					<Stack spacing={isMobile ? 3 : 4} alignItems={'center'}>
						<img src={'/illustrations/dark/notFound.svg'} style={{ height: isMobile ? 160 : 252 }} alt="No activity" />
						<Stack spacing={2} alignItems={'center'}>
							<Typography variant={'h6'}>You have no activity.</Typography>
							<Button
								size={isMobile ? 'large' : 'medium'}
								variant={'contained'}
								component={RouterLink}
								to={'/companies'}
							>
								browse companies
							</Button>
						</Stack>
					</Stack>
				</EmptyContainer>
			)}

			{!isLoading && summLength > 0 && (
				<Stack spacing={isMobile ? 2 : '20px'}>
					<Stack spacing={1} direction={'row'} flexWrap={'wrap'} useFlexGap={true}>
						{generateFilterChip('All', summLength)}
						{inquiriesLength > 0 && generateFilterChip('Inquiry', inquiriesLength)}
						{bidsLength > 0 && generateFilterChip('Bid', bidsLength)}
						{offersLength > 0 && generateFilterChip('Offer', offersLength)}
						{targetPricesLength > 0 && generateFilterChip('Target price', targetPricesLength)}
					</Stack>
					{[
						...filteredBids.map((bid) => ({ ...bid, cardType: 'Bid', sortDate: bid.fields['Created At'] })),
						...filteredOffers.map((offer) => ({ ...offer, cardType: 'Offer', sortDate: offer.createdAt })),
						...inquiries.map((inq) => ({ ...inq, cardType: 'Inquiry', sortDate: inq.fields['Created At'] })),
						...targetPrices.map((inq) => ({ ...inq, cardType: 'Target price', sortDate: inq.fields['Created At'] })),
					]
						.filter((item) => selectedFilter === 'All' || item.cardType === selectedFilter)
						.sort((a, b) => new Date(b.sortDate) - new Date(a.sortDate))
						.map((item, i) => {
							if (item.cardType === 'Bid') {
								return <BidCard key={i} bid={item} />;
							} else if (item.cardType === 'Offer') {
								return <OfferCard key={i} offer={item} />;
							} else if (item.cardType === 'Inquiry') {
								return <InquiryCard key={i} inquiry={item} userContext={user} />;
							} else if (item.cardType === 'Target price') {
								return <TargetPriceCard key={i} inquiry={item} />;
							}

							return <></>;
						})}
				</Stack>
			)}
		</>
	);
};

export default DashboardView;
