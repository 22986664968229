import AppBar from '../../components/AppBar';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../../context';
import { useEffect, useRef } from 'react';
import { Container, Stack, ThemeProvider, useMediaQuery, useTheme } from '@mui/material';
import Footer from '../../components/Footer';
import { MainMobileBox } from '../../styles/common.styles';
import { darkTheme, lightTheme } from '../../styles/theme';
import { ScrollableContainer } from './style';
import { runVersionCheck } from '../../utils/version';

const Main = ({ children }) => {
	const { authenticated, user } = useAuthContext();
	const contentRef = useRef();
	const location = useLocation();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const isSignup = location.pathname.toLowerCase().includes('signup');
	const isSignin = location.pathname.toLowerCase().includes('signin');

	useEffect(() => {
		if (location?.state?.preventScrollReset) {
			return;
		}
		contentRef.current.scrollTo(0, 0);
	}, [location.pathname, location?.state?.preventScrollReset]);

	useEffect(() => {
		if (authenticated) {
			runVersionCheck();
		}
	}, [authenticated]);

	if (isMobile) {
		return (
			<ThemeProvider theme={isSignup || isSignin ? lightTheme : darkTheme}>
				<Stack height={window.innerHeight}>
					{authenticated && <AppBar />}
					<MainMobileBox ref={contentRef} id={'scrollable-container'}>
						{authenticated ? (
							<Container sx={{ marginBottom: 4 }}>{children}</Container>
						) : (
							<Container sx={{ marginBottom: '52px', marginTop: 3 }}>{children}</Container>
						)}
						{!user?.isAdmin && <Footer />}
					</MainMobileBox>
				</Stack>
			</ThemeProvider>
		);
	}

	return (
		<Stack height={'100vh'}>
			{authenticated && <AppBar />}

			<ScrollableContainer ref={contentRef} id={'scrollable-container'}>
				{authenticated ? (
					<Container maxWidth={user?.isAdmin ? 'xl' : undefined} sx={{ marginBottom: '32px' }}>
						{children}
					</Container>
				) : (
					children
				)}
				{location.pathname !== '/signup' && !user?.isAdmin && <Footer />} {/*todo*/}
			</ScrollableContainer>
		</Stack>
	);
};

export default Main;
