export interface ICxO {
	id: number;
	name: string;
	emailPersonal: string;
	emailBusiness: string;
	url: string;
	currentEmployer: string;
	currentPosition: string;
	anchorCompany: string;
	anchorPosition: string;
	category: CXO_CATEGORY;
	status: CXO_STATUS;
	notes: string;
	tier: string;
	lastContacted: string;
	lastContactedNote: string;
	createdAt: string;
	vip: boolean;
	cxo: string;

	follows: Array<any>;
	extPortfolio: string;
}

export enum CXO_CATEGORY {
	EMPLOYEE = 'Employee',
	ADVISOR = 'Advisor',
	CXO = 'CXO',
	INSTI = 'Insti',
	BOARD = 'Board',
	INVESTOR = 'Investor',
}

export enum CXO_STATUS {
	CONTACTED = 'Contacted',
	REPLIED = 'Replied',
	NOT_REPLIED = 'Not Replied',
	ACCEPTED = 'Accepted',
}
